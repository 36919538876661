import React, { useState, useRef } from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { motion } from 'framer-motion';


import './Gallery.css';

const Gallery = () => {

  const galleryImages = [images.img2, images.img11, images.img12, images.img14]
  console.log(galleryImages)
  const scrollRef = useRef(null)
  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;

    }
  }


  return (
    <div className="app__gallery flex-center">
      <div className="app__gallery-content">
        <SubHeading title="Instagram" />
        <h1 className="headtext-cormorant">גלריית תמונות</h1>
        <p className="right">לא משנה אם מדובר ביום הולדת, מפגש משפחתי, מפגש שבועי או ארוחה עם המשפחה הקרובה אין כמו הג'סיקה לאירוע מושלם!</p>
        <a className="links" href="https://www.instagram.com/jessica_beersheva/" rel="noreferrer" target="_blank">
        <motion.button
          whileHover={{
            backgroundColor: '#c3892d',
            scale: 1.1
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          type="button"
          id="all_buttons"
          className="spin insta">
          <span className="day">
            לעמוד
          </span>
          <BsInstagram fontSize='25' />
        </motion.button>
        </a>
      </div>


      <motion.div className="app__gallery-images">
        <motion.div className="app__gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <motion.div className="app__gallery-images_card flex-center" key={`gallery_image-${index + 1}`}>
              <BsInstagram className="gallery__image-icon" />
              <a href="https://www.instagram.com/jessica_beersheva/" rel="noreferrer" target="_blank" >
                <img src={image} alt="gallery_images" />
              </a>

            </motion.div>
          ))}
        </motion.div>
        <div className="app__gallery-images_arrows">
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll("right")} />
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll("left")} />
        </div>
      </motion.div>
    </div>
  )
};

export default Gallery;
