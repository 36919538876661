import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants'
import './Header.css';
import { motion } from 'framer-motion';


const Header = () => {

  const container = {
    hidden: { opacity: 0, y: 100 },
    show: {
      y: 0,
      opacity: 1,
      duration: 0.8,
      transition: {
        staggerChildren: 0.7,

      }
    }
  }

  const item = {
    hidden: { opacity: 0, y: 100 },
    show: {
      opacity: 1,
      y: 0,
    }
  }

  
  const imgItem = {
    offscreen: {
      y: 200,
      x: 200,
      rotate: 70,
      opacity: 0, scale: 0.5
    },
    onscreen: {
      y: 0,
      x: 0,
      rotate: 0,
      opacity: 1,
      scale: 1,

      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 3.8,
        ease: "circOut"
      }
    }
  }

  return (
    <div className="app__header app__wrapper section__padding" id="home">

      <motion.div
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0 }}
        className="app__wrapper_info mob-push">
        <SubHeading title="חוויה עשירה של טעמים" />
        <motion.h1 variants={item} className="app__header-h1 gold">מטבח ישראלי</motion.h1>
        <motion.h1 variants={item} className="app__header-h1 turkiz"> ים תיכוני</motion.h1>
        <motion.p variants={item} className="p-opensans hero-line">
          ג’סיקה, מטבח ישראלי ים תיכוני מציעה למבלים בה מנות איכותיות ומוקפדות בעיצוב ובטעם, <br />בר אלכוהולי עשיר במותגים המובילים, צוות מקצועי, אדיב ומיומן, "חווית שירות אותנטית", אווירה שמחה,<br /> מוזיקת רקע עכשווית ואירועים מיוחדים במהלך השבוע.
        </motion.p>
        <motion.button
          whileHover={{
            backgroundColor: '#c3892d',
            scale: 1.1
          }}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          type="button"
          id="all_buttons"
          className="spin">הזמינו שולחן</motion.button>
      </motion.div>
      <div className="app__wrapper_img">
        {/* <motion.img
          variants={imgItem}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          // animate={{
          //   scale: [1, 1.5, 1.5, 1, 1],
          //   rotate: [0, 0, 180, 180, 0],
          //   borderRadius: ["0%", "0%", "50%", "50%", "0%"]
          // }}
          // transition={{
          //   duration: 2,
          //   ease: "easeInOut",
          //   times: [0, 0.2, 0.5, 0.8, 1],
          //   repeat: 0,
          //   repeatDelay: 1
          // }}
          src={images.hero1} alt="hero" /> */}
      </div>
    </div>
  )
};

export default Header;
