import React from 'react';
import { images, data } from '../../constants';
import { SubHeading, MenuItem } from '../../components'
import { motion } from 'framer-motion';


import './SpecialMenu.css';

const SpecialMenu = () => {

  const imgItem = {
    offscreen: {
      y: -500,
      x: 0,
      rotate: 1270,
      opacity: 0, scale: 0.8
    },
    onscreen: {
      y: 200,
      x: 0,
      rotate: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 11.8,
        ease: "circOut",
      }
    }
  }




  return (
    <div className="app__specialMenu flex-center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title="תפריט שמתאים לחיך שלך" />
        <h1 className="headtext-cormorant">המיוחדים שלנו</h1>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_wine flex-center">
          <p className="app__specialMenu-menu_heading">מיוחדים בצלחת</p>
          <div className="app__specialMenu-menu_items">
            {data.wines.map((wine, index) => (
              <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags={wine.tags} />
            ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_image">
          <motion.img
            variants={imgItem}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 1 }}
            className="app__specialMenu-menu_image-lemon" src={images.lemon} alt="lemon" />
          <img className="app__specialMenu-menu_image-shaker" src={images.shaker} alt="menu" />
        </div>
        <div className="app__specialMenu-menu_cocktails flex-center">
          <p className="app__specialMenu-menu_heading">מיוחדים באלכוהול</p>
          <div className="app__specialMenu-menu_items">
            {data.cocktails.map((cocktail, index) => (
              <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} tags={cocktail.tags} />

            ))}
          </div>
        </div>
      </div>
      <div className="sp_btn">
        <a className="links-btn" href="https://jessica.click2eat.co.il/%d7%94%d7%96%d7%9e%d7%a0%d7%aa-%d7%a9%d7%95%d7%9c%d7%97%d7%9f/" rel="noreferrer" target="_blank">

          <motion.button
            whileHover={{
              backgroundColor: '#c3892d',
              scale: 1.1
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
            type="button"
            id="all_buttons"
            className="spin">הזמינו שולחן</motion.button>
        </a>
      </div>

    </div>
  )
};

export default SpecialMenu;
