import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import J from '../assets/J.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import logo_nav from '../assets/logo_nav.png';
import hero1 from '../assets/hero1.png';
import shaker from '../assets/shaker.png';
import lemon from '../assets/lemon.png';
import hero_bg from '../assets/hero_bg.jpg';
import img1 from '../assets/img1.JPG';
import img2 from '../assets/img2.JPG';
import img3 from '../assets/img3.JPG';
import img4 from '../assets/img4.JPG';
import img5 from '../assets/img5.JPG';
import img6 from '../assets/img6.JPG';
import img7 from '../assets/img7.JPG';
import img8 from '../assets/img8.JPG';
import img10 from '../assets/img10.JPG';
import img11 from '../assets/img11.jpg';
import img12 from '../assets/img12.jpg';
import img13 from '../assets/img13.jpg';
import img14 from '../assets/img14.jpg';
import img15 from '../assets/img15.jpg';
import contact from '../assets/contact.png'
import whatsappicons from '../assets/whats-app-icons.gif'
import food from '../assets/food.pdf';
import drink from '../assets/drink.pdf'
export default {
  bg,
  chef,
  G,
  shaker,
  lemon,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  logo_nav,
  hero1,
  J,
  hero_bg,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  contact,
  whatsappicons,
  food,
  drink
};
