import React from 'react';
import { images } from '../../constants';
import { SubHeading } from '../../components';
import { BsInstagram, BsFacebook, BsWhatsapp } from 'react-icons/bs';
import { motion } from 'framer-motion';

import './Findus.css'

const FindUs = () => {

  const imgItem = {
    offscreen: {
      y: 200,
      x: -200,
      rotate: 40,
      opacity: 0, scale: 0.5
    },
    onscreen: {
      y: 0,
      x: 0,
      rotate: 0,
      opacity: 1,
      scale: 1,

      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 3.8,
        ease: "circOut"
      }
    }
  }


  return (
    <div className="app__bg app__wrapper section__padding" id="contact">
      <div className="app__wrapper_info-contact" >
        <SubHeading title="שעות פעילות" />
        <h1 className="headtext-cormorant-contact">מצאו אותנו</h1>
        <div className="app__wrapper-content">
          <p className="contact-line">מתחם מבנה, יצחק נפחא 25, באר שבע</p>
          <p className="contact-line">א – ש | 12:00 – עד הלקוח האחרון</p>
          <div className="social-find">
            <a className="icons" href="https://www.instagram.com/jessica_beersheva/ " target="_blank" rel="noreferrer" ><BsInstagram color="#fff" fontSize="25" /></a>
            <a className="icons" href="https://www.facebook.com/JessicaBeerSheva" target="_blank" rel="noreferrer"><BsFacebook color="#fff" fontSize="25" /></a>
            <a className="icons" href="whatsapp:+9725236723" target="_blank" rel="noreferrer"><BsWhatsapp color="#fff" fontSize="25" /></a>


          </div>
          <a className="links-btn" href="https://jessica.click2eat.co.il/%d7%94%d7%96%d7%9e%d7%a0%d7%aa-%d7%a9%d7%95%d7%9c%d7%97%d7%9f/" rel="noreferrer" target="_blank">
          <motion.button
            whileHover={{
              backgroundColor: '#c3892d',
              scale: 1.1
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
            type="button"
            id="all_buttons"
            className="spin">הזמינו שולחן</motion.button>
            </a>
        </div>
      </div>
      <div className="app__wrapper_img-contact">
        <motion.img
          variants={imgItem}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
           src={images.contact} alt="find us" />
      </div>

    </div>
  )
};

export default FindUs;
