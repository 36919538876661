import React, { useState } from 'react';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images'
import './Navbar.css';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import {motion} from "framer-motion";



const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const container2 = {
      opacity:0,
      hidden: { y: "-100%",x:0},
    show: {
      x:0,
      y:0,
      duration: 0.07,
      transition: {
        delay: 0,
        type: "spring" ,
      }
    }
  }
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logo_nav} alt="app logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">ראשי</a></li>
        <li className="p__opensans"><a href="#about">אודות</a></li>
        <li className="p__opensans"><a href="#menu">
          <Dropdown className="p__opensans" title="תפריטים">
            <Dropdown.Item>תפריט אוכל</Dropdown.Item>
            <Dropdown.Item>תפריט אלכוהול</Dropdown.Item>
          </Dropdown>
        </a>
        </li>
        {/* <li className="p__opensans"><a href="#access">נגישות</a></li> */}
        <li className="p__opensans"><a href="#contact">צרו קשר</a></li>
      </ul>
      <div>
        <ul className="app__navbar-links">
          <li className="p__opensans "><a className="table" href="https://jessica.click2eat.co.il/" target="_blank" rel="noreferrer"> הזמנת שולחן</a></li>
          <li className="p__opensans "><a className="order" rel="noreferrer" href="https://www.tabitorder.com/?site=611e227335163c8b39d469d6&service=delivery&channel=TAW&_ga=2.104739648.1332902043.1648453561-381545515.1648453561#/start" target="_blank">הזמנת משלוח </a></li>
        </ul>
      </div>
      <div className="app__navbar-smallscreen">
      <div className="app__navbar-logo-mob"> <img src={images.logo_nav} alt="app logo" /></div>
        <div className="app__navbar-seat" >
          <ul className="app__navbar-links-order">
            <li className="p__opensans "><a className="table" href="https://jessica.click2eat.co.il/" target="_blank" rel="noreferrer"> הזמנת שולחן</a></li>
            <li className="p__opensans "><a className="order" rel="noreferrer" href="https://www.tabitorder.com/?site=611e227335163c8b39d469d6&service=delivery&channel=TAW&_ga=2.104739648.1332902043.1648453561-381545515.1648453561#/start" target="_blank">הזמנת משלוח </a></li>
          </ul>
        </div>
        <div className="app__navbar-menu" > <HiOutlineMenuAlt3 className="humburger" color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} /></div>

          {toggleMenu && (
            <motion.div
            className="app__navbar-smallscreen_overlay flex-center slide-bottom"
            variants={container2}
            initial="hidden"
            animate="show"
            
            
            >
              <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
              <ul className="app__navbar-smallscreen_links">
                <li className="p__opensans"><a href="#home" onClick={() => setToggleMenu(false)}>ראשי</a></li>
                <li className="p__opensans"><a href="#about" onClick={() => setToggleMenu(false)}>אודות</a></li>
                <li className="p__opensans"><a href="#menu" onClick={() => setToggleMenu(false)}>
                  <Dropdown className="p__opensans sub" title="תפריטים">
                    <Dropdown.Item onClick={() => setToggleMenu(false)}>תפריט אוכל</Dropdown.Item>
                    <Dropdown.Item onClick={() => setToggleMenu(false)}>תפריט אלכוהול</Dropdown.Item>
                  </Dropdown>
                </a>
                </li>
                {/* <li className="p__opensans"><a onClick={() => setToggleMenu(false)} href="#access">נגישות</a></li> */}
                <li className="p__opensans"><a onClick={() => setToggleMenu(false)} href="#contact">צרו קשר</a></li>
              </ul>
            </motion.div>)
          }

      </div>
    </nav >
  )

};

export default Navbar;