import React from 'react';
import { FooterOverlay, Newsletter } from '../../components'
import { BsInstagram, BsFacebook, BsWhatsapp } from 'react-icons/bs';
import images from '../../constants/images'
import Lottie from 'react-lottie';
import * as whatsapp from '../../whats-app-icons.json'
import './Footer.css';

const Footer = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: whatsapp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };




  return (
    <div className="app__footer section__padding">
      <FooterOverlay />
      <Newsletter />

      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">צרו קשר</h1>
          <a className="links" href="https://ul.waze.com/ul?preview_venue_id=22806840.228133941.492352&navigate=yes" target="_blank" rel="noreferrer" >
            <p className="p_tzru">מתחם מבנה, יצחק נפחא 25, באר שבע</p>
          </a>
          <a className="links" href="tel:077-9974643" target="_blank" rel="noreferrer"> <p className="p_tzru">077-9974643</p> </a>
        </div>
        <div className="app__footer-links_logo">
          <img className="footer__logo" src={images.logo_nav} alt="app logo" />
          <p className="p_tzru"> "חווית שירות אותנטית"</p>
          <img src={images.spoon} alt="spoon" className="spoon__imag" />
          <div className="social">
            <a className="icons" href="https://www.instagram.com/jessica_beersheva/ " target="_blank" rel="noreferrer" ><BsInstagram color="#fff" fontSize="25" /></a>
            <a className="icons" href="https://www.facebook.com/JessicaBeerSheva" target="_blank" rel="noreferrer"><BsFacebook color="#fff" fontSize="25" /></a>
            <a className="icons" href="whatsapp:+9725236723" target="_blank" rel="noreferrer"><BsWhatsapp color="#fff" fontSize="25" /></a>
          </div>
        </div>
        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">שעות פעילות</h1>
          <p className="p_tzru">א – ש | 12:00 – עד הלקוח האחרון</p>

        </div>
      </div>
      <div className="footer__copyright">
        <p className="p__copy">כל הזכויות שמורות לג'סיקה 2022@</p>

      </div>
      <div className="footer__fixes">
        <div className="footer__left">
          <a href="whatsapp:+9725236723" target="_blank" rel="noreferrer" ><Lottie
            options={defaultOptions}
            height={70}
            width={70}
            isStopped={null}
            isPaused={null}
          />
          </a>
        </div>

      </div>
    </div>
  )
};

export default Footer;
