import images from './images';

const wines = [
  {
    title: 'טטאקי סנטה',
    price: '78₪',
    tags: "סינטה צלויה עדין, רוטב מייפל חמצמץ וצ'מיצ'ורי פיקטני",
  },
  {
    title: 'ברוסקטה שקדי עגל',
    price: '56₪',
    tags: "איולי צ'יפוטלה, שקדי עגל, סלט ירוק ואגוזי",
  },
  {
    title: 'לברק',
    price: '86₪',
    tags: 'פילה בקראסט פיסטוק על מצע ניוקי, סלרי וארטישוק ברוטב שמנת',
  },
  {
    title: 'קבב של שוק',
    price: '72₪',
    tags: 'פיתה על הגז, בצל סומק וכוסברה, טחינה ועמבה',
  },
  {
    title: 'טרטר פילה בקר',
    price: '72₪',
    tags: ' פילה בקר',
  },
];

const cocktails = [
  {
    title: 'מורדי',
    price: '48₪',
    tags: 'עראק נח פרימיום | ליים | בזיליקום | נענע | פרח הסמבוק | למון גראס ',
  },
  {
    title: "French Bride",
    price: '54₪',
    tags: 'גלו ליווט 12 | טריפל סק | ליים | רוזטה',
  },
  {
    title:" ג'סיקה האני האני",
    price: '52₪',
    tags: 'ביפיטר | פאולנר | ליים | תות',
  },
  {
    title: 'Popai',
    price: '52₪',
    tags: 'שרטרז ירוק | ליים | מידורי | פלרנוס | למון גראס | ביטר אונגוסטורה',
  },
  {
    title: "גרין ג'ין",
    price: '52₪',
    tags: " ג'ין ביפיטאר  | בזיליקום | ג'ינג'ר | ליים | טוניק",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
