import React from 'react';
import images from '../../constants/images'
import { motion } from 'framer-motion';

import photo from '../../assets/drink.pdf'
import food from '../../assets/food.pdf'


import './AboutUs.css';

const AboutUs = () => {

  const imgItem = {
    offscreen: {
      y: 200,
      x: -200,
      rotate: 140,
      opacity: 0, scale: 0.5
    },
    onscreen: {
      y: 0,
      x: 0,
      rotate: 0,
      opacity: 1,
      scale: 1,

      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 3.8,
        ease: "circOut"
      }
    }
  }





  return (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
      <div className="app__aboutus-overlay flex__center">
        <img src={images.J} alt="J" />
      </div>
      <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about to-right">
          <h1 className="headtext-cormorant">עלינו</h1>
          <img src={images.spoon} alt="spoon" className="spoon_img" />
          <p className="p__right right">
            ג'סיקה רסטרו בר באר שבע  מביאה איתה אל העיר קסם של מטבח ישראלי ים תיכוני, ואווירה ייחודית,
            תוך שילוב מלהיב בין אוכל משובח, מוזיקה טובה ובילוי אלכוהולי מהנה.
          </p>
          <a href={food} target="_blank" rel="noreferrer">

          <motion.button
            whileHover={{
              backgroundColor: '#c3892d',
              scale: 1.1
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
            type="button"
            id="all_buttons"
            className="spin">תפריט אוכל
          </motion.button>
          </a>
        </div>
        <div className="app__aboutus-content_knife flex-center">
          <motion.img
            variants={imgItem}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0 }}
            src={images.knife} alt="" />
        </div>
        <div className="app__aboutus-content_history">
          <h1 className="headtext-cormorant">הסיפור שלנו</h1>
          <img src={images.spoon} alt="spoon" className="spoon_img" />
          <p className="p__left left">
            ג'סיקה רסטרו בר באר שבע  מביאה איתה אל העיר קסם של מטבח ישראלי ים תיכוני, ואווירה ייחודית,
            תוך שילוב מלהיב בין אוכל משובח, מוזיקה טובה ובילוי אלכוהולי מהנה.
          </p>
          <a href={photo} target="_blank" rel="noreferrer">
            <motion.button
              whileHover={{
                backgroundColor: '#c3892d',
                scale: 1.1
              }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
              type="button"
              id="all_buttons"
              className="spin">תפריט אלכוהול
            </motion.button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default AboutUs;
