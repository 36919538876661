import React from 'react';
import { images } from '../../constants'

const SubHeading = ({title}) => (
  <div style={{marginBottom:'1rem'}}>
    <p style={{color:'white'}} className="p_narkiss">{title}</p>
    <img src={images.spoon} alt="spoon" className="spoon_image" />
  </div>
);

export default SubHeading;
