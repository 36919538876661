import React, { useState } from 'react';
// import { SubHeading } from '../../components';
import './Newsletter.css';

import { send } from 'emailjs-com';



const Newsletter = () => {
  const [valid , setValid] = useState(true)
  const [FormData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",

  });

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

    const phoneValid = FormData.phone
    console.log(phoneRegex.test(phoneValid))

    try {
      if(phoneRegex.test(phoneValid)){
        setValid(true)
      send(
        'service_no0pp3q',
        'template_o7buuvt',
        FormData,
        'GsQja2lYj2wvncOCe'

      )
      }else{
        setValid(false)
      }

    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="app__newsletter">
      <div className="app__newsletter-heading">
        {/* <SubHeading title="יצירת קשר" /> */}
        <h1 className="headtext-cormorant-news">רוצים לשאול אותנו משהו?</h1>
        <h1 className="headtext-cormorant-news"> השאירו פרטים ונחזור אליכם בהקדם</h1>

      </div>
      <div className="app__newslatter-input flex__center">
        <form className="app__form" onSubmit={handleSubmit}>
          <div className="app__form-name">
            <input className="inputs" type="text" name="name" id="name" placeholder="שם מלא" required  onChange={handleChange} />
            <input className="inputs" type="tel" name="phone" id="phone" placeholder="טלפון" required onChange={handleChange} />
          </div>
          {!valid && <span className="input__error">מספר טלפון לא תקין ,נא להזין מספר אחר</span>}
          <div className="app__form-email">
            <input className="inputs" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" type="email" name="email" id="email" placeholder="מייל" required onChange={handleChange} />
            <input className="inputs" type="textarea" name="message" id="message" placeholder="מה תרצו לשאול" onChange={handleChange} />
          </div>
          <input className="inputs btn" type="submit" value="שלח" />
        </form>
      </div>
    </div>
  )
};

export default Newsletter;
